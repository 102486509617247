'use client'

import Image from 'next/image'

import { ProductName } from '@/components/product-name'
import { selectors } from '@/common/constants/selectors-constants'
import { ProductDetailStorage } from '@/modules/product/components/detail/components/storage/product-detail-storage'
import { ConfigurableProductModalData } from '@/common/types/product-types'
import { ConfigurableVariants } from '../variants'
import { AddToCartEventHandlerParams } from '@/providers/cart/cart.types'

export type Props = {
  isLongTermUnavailable: boolean
  isInStock: boolean
  productData: ConfigurableProductModalData
  onClose?: () => void
  preselectedVariantSku?: string
  onAddToCartEvent?: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function PurchaseDialogConfigurableVariants({
  isLongTermUnavailable,
  isInStock,
  productData,
  onClose,
  preselectedVariantSku,
  onAddToCartEvent,
}: Props): JSX.Element {
  return (
    <div className="mb-4 text-left">
      <Image
        src={productData.image?.url?.full ?? ''}
        alt={productData.name ?? 'Gymbeam Product'}
        priority
        width={500}
        height={500}
        className="object-contain w-full h-[215px]"
        data-test="variant-image"
      />

      <ProductName size="lg" dialogVariant data-test={selectors.CP.productName}>
        {productData.name}
      </ProductName>

      <div className="-mt-6 mb-1">
        <ProductDetailStorage
          isConfigurableProduct
          longTermUnavailable={isLongTermUnavailable}
          isGiftCardProduct={false}
          inStock={isInStock}
          small
        />
      </div>

      <ConfigurableVariants
        configurableProductData={productData}
        isPopupVariant
        onClose={() => onClose?.()}
        preselectedVariantSku={preselectedVariantSku}
        onAddToCartEvent={onAddToCartEvent}
      />
    </div>
  )
}
