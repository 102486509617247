'use client'
import { useTranslations } from 'next-intl'
import { twJoin, twMerge } from 'tailwind-merge'

import { Currency } from '@/components/currency'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Tooltip } from '@/components/tooltip'
import { useAuthContext, useStoreContext } from '@/providers'
import { obsoloteGetPrice } from '@/utils'
import { DeliveryInfo } from './delivery-info'
import { UnitPrice } from './unit-price'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/product-data-context'
import VatAndShippingMessage from './vat-and-shipping-message'
import { DeliveryData } from 'src/app/api/delivery-date-info/schema'

type PriceProps = {
  inStock: boolean
  deliveryData?: DeliveryData
  small?: boolean
}

export function Price({
  deliveryData,
  inStock,
  small,
}: PriceProps): JSX.Element | null {
  const t = useTranslations('Product')
  const { customerData } = useAuthContext()
  const { configurableProductVariant } = useProductDataContext()
  const { storeCode } = useStoreContext()
  const {
    showRegularPrice,
    recommendedPrice,
    finalPrice,
    wholesaleDiscountPercentageFormatted,
    wholesaleDiscountPercentage,
  } = obsoloteGetPrice({
    price_range: configurableProductVariant?.product?.price_range,
    msrp_price: configurableProductVariant?.product?.msrp_price,
  })

  const unitPrice = configurableProductVariant?.product?.unit_price

  const wholesaleDiscount = t.rich('detail.price.discount.wholesaleDiscount', {
    percent: () =>
      `${
        wholesaleDiscountPercentage ? '-' : ''
      }${wholesaleDiscountPercentageFormatted}`,
  })

  const isB2B = !!customerData?.is_b2b
  if (inStock) {
    return (
      <>
        <div data-test={selectors.PDP.productPrice} className="mt-4">
          <Currency
            className={twJoin(
              'text-secondary',
              small ? 'text-[15px]' : 'text-lg',
              'font-bold',
            )}
            currency={finalPrice?.currency}
            price={finalPrice?.value}
          />
        </div>
        {showRegularPrice && (
          <div className="text-grey-300 text-xs flex items-center">
            <Currency
              className="line-through"
              currency={recommendedPrice?.currency}
              price={recommendedPrice?.value}
            />
            <span className="cursor-help inline-flex ml-1">
              <Tooltip
                direction="right"
                text={
                  <span className="w-[210px] inline-block text-tooltip-text">
                    {isB2B
                      ? t('detail.price.discount.recommendedRetailPrice')
                      : t('detail.price.lowestLastPrice')}
                  </span>
                }
              >
                <QuestionMarkRound
                  className="fill-grey-300"
                  width={18}
                  height={18}
                />
              </Tooltip>
            </span>
          </div>
        )}
        <div className="flex [&>*:not(:last-child)]:after:content-[','] [&>*:not(:last-child)]:after:mr-1">
          {unitPrice && <UnitPrice unitPrice={unitPrice} />}
          <VatAndShippingMessage
            hasFreeShipping={hasFreeShipping(
              finalPrice?.value,
              deliveryData?.freeShippingThreshold,
            )}
            storeCode={storeCode}
          />
        </div>
        {isB2B && showRegularPrice && (
          <div className="py-2">{wholesaleDiscount}</div>
        )}
        {!isB2B && deliveryData && deliveryData.methods.length > 0 && (
          <div
            className={twJoin(
              'flex flex-col md:flex-row',
              'md:items-start',
              'text-grey-300',
            )}
          >
            <DeliveryInfo
              deliveryData={deliveryData}
              productPrice={finalPrice?.value}
            />
          </div>
        )}
      </>
    )
  } else if (finalPrice) {
    return (
      <div className="mt-2 price-estimated">
        <span className={twMerge('label', 'text-lg font-bold')}>
          {t('detail.price.expectedPrice')}&nbsp;
        </span>
        <Currency
          className="text-secondary text-lg font-bold"
          currency={finalPrice?.currency}
          price={finalPrice?.value}
        />
      </div>
    )
  } else {
    return null
  }
}

export const hasFreeShipping = (
  price?: number | null,
  thresholdPrice?: number,
): boolean => {
  if (price === undefined || price === null || thresholdPrice === undefined)
    return false
  return price >= thresholdPrice
}
