'use client'

import {
  createContext,
  useContext,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  RefObject,
  useCallback,
  useMemo,
} from 'react'

import {
  ConfigurableProductVariantFragment,
  GiftCardProductAmountFragment,
} from '@/api'
import {
  getInitialConfigurableProductVariant,
  getInitialGiftCardProductAmount,
  processRecentlyViewedProducts,
} from './utils'
import {
  ConfigurableVariants,
  GiftCardAmountsData,
} from '@/common/types/product-types'
import { ProductTabId } from '@/modules/product/components/tabs/product-tabs-types'

export interface ProductDataContextType {
  configurableProductVariant: ConfigurableProductVariantFragment | undefined
  setConfigurableProductVariant: Dispatch<
    SetStateAction<ConfigurableProductVariantFragment | undefined>
  >

  giftCardProductAmount: GiftCardProductAmountFragment | undefined
  setGiftCardProductAmount: Dispatch<
    SetStateAction<GiftCardProductAmountFragment | undefined>
  >

  productVariantCount: number
  setProductVariantCount: Dispatch<SetStateAction<number>>

  tabsSectionRef: RefObject<HTMLDivElement> | null
  setTabsSectionRef: Dispatch<SetStateAction<RefObject<HTMLDivElement> | null>>

  tabsSectionActiveTab: ProductTabId | undefined
  setTabsSectionActiveTab: Dispatch<SetStateAction<ProductTabId | undefined>>

  configurableVariants: ConfigurableVariants | null | undefined
}

export const ProductDataContext = createContext<ProductDataContextType>(
  {} as ProductDataContextType,
)

export type ProductDataContextProviderProps = PropsWithChildren<{
  defaultTab?: ProductTabId
  productId?: number | null
  productSku?: string | null
  configurableVariants: ConfigurableVariants | null | undefined
  giftCardAmounts: GiftCardAmountsData | null | undefined
  incrementToLastViewed?: boolean
  outOfStock?: boolean
}>

export const ProductDataContextProvider = ({
  defaultTab,
  productId,
  productSku,
  configurableVariants,
  giftCardAmounts,
  incrementToLastViewed,
  outOfStock,
  children,
}: PropsWithChildren<ProductDataContextProviderProps>) => {
  const [productVariantCount, setProductVariantCount] = useState(1)

  const [configurableProductVariant, setConfigurableProductVariant] = useState(
    getInitialConfigurableProductVariant(configurableVariants),
  )

  const [giftCardProductAmount, setGiftCardProductAmount] = useState(
    getInitialGiftCardProductAmount(giftCardAmounts),
  )

  const [tabsSectionRef, setTabsSectionRef] =
    useState<RefObject<HTMLDivElement> | null>(null)

  const setTabsSectionRefCallback = useCallback(
    (tabsSectionRef: RefObject<HTMLDivElement>) => {
      setTabsSectionRef(tabsSectionRef)
    },
    [],
  )

  const [tabsSectionActiveTab, setTabsSectionActiveTab] = useState<
    ProductTabId | undefined
  >(defaultTab)

  // Save product ID and SKU to local storage as recently viewed product
  useEffect(() => {
    if (incrementToLastViewed) {
      processRecentlyViewedProducts({ productId, productSku, outOfStock })
    }
  }, [productId, productSku, incrementToLastViewed, outOfStock])

  const contextValue = useMemo(
    () => ({
      productVariantCount,
      setProductVariantCount,

      configurableProductVariant,
      setConfigurableProductVariant,

      giftCardProductAmount,
      setGiftCardProductAmount,

      tabsSectionRef,
      setTabsSectionRef: setTabsSectionRefCallback,

      tabsSectionActiveTab,
      setTabsSectionActiveTab,

      configurableVariants,
    }),
    [
      tabsSectionRef,
      productVariantCount,
      tabsSectionActiveTab,
      giftCardProductAmount,
      configurableProductVariant,
      setTabsSectionRefCallback,
      configurableVariants,
    ],
  )

  return (
    <ProductDataContext.Provider value={contextValue}>
      {children}
    </ProductDataContext.Provider>
  )
}

export const useProductDataContext = () => useContext(ProductDataContext)
