import React from 'react'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import { BadgeDeliveryDateTooltip } from './badge-delivery-date-tooltip'
import { DeliveryData } from 'src/app/api/delivery-date-info/schema'

interface BadgeDeliveryDateProps {
  deliveryData: DeliveryData
  isGiftCardProduct: boolean
}

export const BadgeDeliveryDate = ({
  deliveryData,
  isGiftCardProduct,
}: BadgeDeliveryDateProps) => {
  const t = useTranslations('Product')
  const { title, methods } = deliveryData

  return isGiftCardProduct ? (
    <span
      className={twJoin(
        'block',
        'bg-success text-white',
        'font-bold',
        'px-2 py-0.5',
      )}
    >
      {t('detail.badge.deliveryNow')}
    </span>
  ) : (
    <BadgeDeliveryDateTooltip methods={methods}>
      <span
        className={twJoin(
          'block cursor-help h-full',
          'bg-success text-white',
          'font-bold',
          'px-2 py-0.5',
        )}
      >
        {title}
      </span>
    </BadgeDeliveryDateTooltip>
  )
}
