import { useTranslations } from 'next-intl'

import { StoreCodeType } from '@/common/types'

export default function VatAndShippingMessage({
  hasFreeShipping,
  storeCode,
}: {
  hasFreeShipping: boolean
  storeCode: StoreCodeType
}) {
  const t = useTranslations('Product.detail.shipping')

  if (storeCode !== 'gymbeamde') return null

  return (
    <div>
      {hasFreeShipping ? t('vatAndFreeShipping') : t('vatAndPaidShipping')}
    </div>
  )
}
